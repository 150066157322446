import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";

const StationPage = () => import("@/modules/orders/views/Stations/Show.vue");
const NewStation = () => import("@/modules/orders/views/Stations/New.vue");
const EditStation = () => import("@/modules/orders/views/Stations/Edit.vue");

export default {
    path: "/",
    component: DashboardLayout,
    name: "Station",
    children: [{
        path: "/stations/create",
        name: "NewStation",
        component: NewStation,
        meta: {
            groupName: "Stations",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "station-create",
        },
    },
    {
        path: "/stations/update/:id",
        name: "EditStation",
        component: EditStation,
        meta: {
            groupName: "Stations",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "station-update",
        },
    },
    {
        path: "/stations",
        name: "StationPage",
        component: StationPage,
        meta: {
            groupName: "Stations",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-station-index",
        },
    },
    ],
};