class PowerMetersOrderService {
  get_orders(page, data) {
    return axios.post('general/power_meters_orders?page=' + page, data)
  }
  create() {
    return axios.get('general/power_meters_orders/create')
  }
  get_order(id) {
    return axios.get('general/power_meters_orders/view/' + id)
  }
  add_order(data) {
    return axios.post('general/power_meters_orders/store', data)
  }
  edit_order(id, data) {
    return axios.post('general/power_meters_orders/update/' + id, data)
  }
  activate_order(id) {
    return axios.post('general/power_meters_orders/activate/' + id)
  }
  appointment_order(id, data) {
    return axios.post('general/power_meters_orders/set_appointment/' + id, data)
  }

  // get_elect_data(id, data) {
  //   return axios.post('general/power_meters_orders/set_appointment/' + id, data)
  // }
}
export default new PowerMetersOrderService();