class StationService {
  get_stations(page, data) {
    return axios.post('general/power_meters?page=' + page, data)
  }
  get_station(id) {
    return axios.get('general/power_meters/view/' + id)
  }
  add_station(data) {
    return axios.post('general/power_meters/store', data)
  }
  edit_station(id, data) {
    return axios.post('general/power_meters/update/' + id, data)
  }
  destroy_station(id) {
    return axios.delete('general/power_meters/destroy/' + id)
  }
}
export default new StationService();