import OrderService from '../services/order.service';
import i18n from '@/i18n.js'
import router from '@/router'
import { set_obj } from "../helper/helper"
export const order = {
    namespaced: true,
    state: () => ({
        order: null,
        reports: [],
        status_order: false,
        all_pledge_count: 0,
        pledge_count: 0,
        all_specialities_count: 0,
        current_ser_specialities_count: 0,
        req_spacialist: [],
        all_specialities: [],
        station: null,
        zip_file_count: 0,
        hide_btn_spacilist: false,
        order_specialists: []
    }),
    getters: {
        req_pledge_count(state) {
            return state.all_pledge_count - state.pledge_count;
        },

        req_serv_count(state) {
            return state.all_specialities_count - state.current_ser_specialities_count;
        },

        req_spacialist_attach(state) {
            return state.zip_file_count;
        },
        get_order_specialists(state) {
            let data = [];
            if (state.order_specialists.length > 0) {
                for (let i = 0; i < state.order_specialists.length; i++) {
                    const element = state.order_specialists[i];
                    var attachments = {
                        pdf: null,
                        dwg: null,
                        word: null,
                        zip: null,
                    }
                    var hide_btn_spacilist = false;

                    if (element.order_specialist_reject_log.length > 0) {
                        hide_btn_spacilist = true
                    }

                    var obj1 = null;
                    if (element.specialist.pdf.length > 0) {
                        element.specialist.pdf.forEach((a, c, arr) => {
                            if (a.status == "approved") arr.length = c + 1;
                            obj1 = set_obj(a, c, i18n.t("PDF file"), obj1);
                        });
                        attachments.pdf = obj1;
                    }

                    var obj2 = null;
                    if (element.specialist.word.length > 0) {
                        element.specialist.word.forEach((a, c, arr) => {
                            if (a.status == "approved") arr.length = c + 1;
                            obj2 = set_obj(a, c, i18n.t("DOCS file"), obj2);
                        });
                        attachments.word = obj2;
                    }

                    var obj3 = null;
                    if (element.specialist.dwg.length > 0) {
                        element.specialist.dwg.forEach((a, c, arr) => {
                            if (a.status == "approved") arr.length = c + 1;
                            obj3 = set_obj(a, c, i18n.t("file DWG"), obj3);
                        });
                        attachments.dwg = obj3;
                    }

                    var obj4 = null;
                    if (element.specialist.zip.length > 0) {
                        element.specialist.zip.forEach((a, c, arr) => {
                            if (a.status == "approved") arr.length = c + 1;
                            obj4 = set_obj(a, c, i18n.t("file zip"), obj4);
                        });
                        attachments.zip = obj4;
                    }

                    data.push({
                        hide_btn_spacilist: hide_btn_spacilist,
                        attachments: attachments,
                        order_service: element.order_service
                    });
                }
            }

            return data;
        }
    },
    mutations: {
        SET_ORDER_SPACILIST(state, data) {
            state.order_specialists = data
        },
        SET_SPECILIST(state, data) {
            state.all_specialities = data
        },
        SET_COUNT_SPECIALITS(state, data) {
            state.all_specialities_count = data
        },
        SET_SERV_SPECIALITS(state, data) {
            state.current_ser_specialities_count = data
        },
        SET_CURRENT_SPECILIST(state, data) {
            state.req_spacialist = data
        },
        SET_ZIP_FILE_COUNT(state, data) {
            state.zip_file_count = data
        },
        DECREASE_ATTACHMENT_SPECIALITS(state) {
            state.zip_file_count--; // مرفقات التخصصات بتقابل مرفق واحد لكل تخصص فكل رفع لمرفق بنطرح قيمة
        },
        INCREASE_ATTACHMENT_SPECIALITS(state) {
            state.zip_file_count++; // مرفقات التخصصات بتقابل مرفق واحد لكل تخصص فكل رفع لمرفق بنجمع قيمة بكل عملية حذف
        },
        INCREASE_SERV_SPECIALITS(state, data) {
            state.current_ser_specialities_count++; // بنطرح من قيمة الحالية لعدد الاختصاصات يلي انضفلها خدمات
            state.req_spacialist.push(data)
        },
        DECREASE_SERV_SPECILIST(state, data) {
            // alert('DECREASE_SERV_SPECILIST')
            state.current_ser_specialities_count--; // بنطرح من قيمة الحالية لعدد الاختصاصات يلي انضفلها خدمات
            // state.all_specialities[index].order_service_count--
            var index = state.req_spacialist.indexOf(data);
            if (index > -1) {
                state.req_spacialist.splice(index, 1);
            } // بنشيك عالمصفوفة يلي بتحتوي ids الاختصاصات المطلوبة 
            //اذا كان الاختصاص موجود بنحذفو
        },
        INCREASE_SERV(state, data) {
            state.all_specialities.map(v => {
                if (data == v.specialty_id)
                    return v.order_service_count++
            })
        },
        DECREASE_SERV(state, index) {
            // alert('DECREASE_SERV')
            state.all_specialities[index].order_service_count--
        },

        INCREASE_ALL_PLEDGE(state) {
            state.all_pledge_count++
        },
        DECREASE_ALL_PLEDGE(state) {
            state.all_pledge_count--
        },
        INCREASE_PLEDGE(state) {
            state.pledge_count++
        },
        DECREASE_PLEDGE(state) {
            state.pledge_count--
        },
        SET_ALL_PLEDGE(state, data) {
            state.all_pledge_count = data
        },
        SET_PLEDGE(state, data) {
            state.pledge_count = data
        },
        SET_ORDER(state, data) {
            state.order = data
        },
        SET_REPORT(state, data) {
            state.reports = data
        },
        SET_STATUS(state, data) {
            // alert('remove btn approve')
            state.status_order = data
        },
        HIDE_REJECT_SPACILIST(state, data) {
            state.hide_btn_spacilist = data
        }
    },
    actions: {
        renew_license_ready({ rootState }, item) {
            return OrderService.renew_license_ready(item.id).then(
                (response) => {
                    // rootState.table.items.map(v => {
                    //     if (v.id == item.id) {
                    //         v.status.status = response.data.data.status.status
                    //         v.status_id = response.data.data.status_id
                    //         return v;
                    //     }
                    // });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        rejected_order_by_tsleem({ rootState }, item) {
            return OrderService.rejected_order_by_tsleem(item.id).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        // change status request

        change_status_request({ rootState }, data) {
            return OrderService.change_status_cancel_request(data).then(
                (response) => {
                    // console.log(response)
                    rootState.table.items.map(v => {
                        if (v.id == data.canceled_request_id) {
                            v.status = response.data.data.status
                            v.status_text = response.data.data.status_text
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        // report 
        change_report_status({ rootState, commit }, data) {
            data.append('achive_report_id', rootState.id);
            // data.append('status', 'approve');
            return OrderService.change_report_status(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status = response.data.data.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        // order

        // من قبل مقدم  الطلب
        cancel_order({ rootState }, item) {
            return OrderService.cancel_order(item.id).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        add_order({ rootState }, data) {
            if (rootState.auth.type.code == 'service_provider' || rootState.auth.type.code == 'gov') {
                data.append('receiver_id', rootState.auth.user.id)
            } else {
                if (!data.has('receiver_id')) {
                    data.append('receiver_id', rootState.auth.user.id)
                }
            }
            return OrderService.add_order(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },
        update_order({ rootState }, data) {
            if (rootState.auth.type.code == 'service_provider' || rootState.auth.type.code == 'gov') {
                data.append('receiver_id', rootState.auth.user.id)
            } else {
                if (!data.has('receiver_id')) {
                    data.append('receiver_id', rootState.auth.user.id)
                }
            }
            return OrderService.update_order(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },
        re_assign_order({ rootState }, data) {
            return OrderService.re_assign_order(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );

        },
        change_specialist({ commit, rootState }, item) {
            commit('form/SET_DIALOG', true, { root: true });
            rootState.id = item.id
            commit("SET_FUNCTION", "action_change_specialist", { root: true });
        },
        action_change_specialist({ rootState, commit }, data) {
            return OrderService.change_specialist(rootState.id, data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.specialist = response.data.data.specialist
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        approve_order_design_office({ commit, rootState }, item) {
            commit('form/SET_DIALOG', true, { root: true });
            rootState.id = item.id
            commit("SET_FUNCTION", "action_approve_design_office", { root: true });
        },
        action_approve_design_office({ rootState, commit }, data) {
            return OrderService.approve_add_specialist(rootState.id, data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        reject_order_design_office({ commit, rootState }, item) {
            commit('form/SET_DIALOG', true, { root: true });
            rootState.id = item.id
            commit("SET_FUNCTION", "action_rejected", { root: true });
        },
        action_rejected({ rootState, commit }, data) {
            console.log(...data)
            data.append('order_id', rootState.id)
            // data.append('current_status', order.status_id)
            // return
            return OrderService.rejected_order(data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        action_cancel_constructor_consultingt({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.cancel_consulting_contractor(data).then(
                (response) => {
                    // rootState.table.items.map(v => {
                    //     if (v.id == rootState.id) {
                    //         v.status_id = response.data.data.status_id
                    //         v.status.status = response.data.data.status.status
                    //         return v;
                    //     }
                    // });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        action_edit_constructor_consultingt({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.edit_constructor_consultingt(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        action_choos_cont_consult({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.change_choosing(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.contracter_id = response.data.data.contracter_id
                            v.consulting_id = response.data.data.consulting_id
                            v.garbage_id = response.data.data.garbage_id
                            if (response.data.data.contracter && response.data.data.contracter.company)
                                v.contracter.company.name = response.data.data.contracter.company.name
                            if (response.data.data.consultor && response.data.data.consultor.company)
                                v.consultor.company.name = response.data.data.consultor.company.name
                            if (response.data.data.garbage)
                                v.garbage.name = response.data.data.garbage.name
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_change_cont_consult({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.change_consulting_contractor(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.contracter_id = response.data.data.contracter_id
                            v.consulting_id = response.data.data.consulting_id
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_update_consulting({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.update_consulting(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.consulting_id = response.data.data.consulting_id
                            v.consultor.company.name = response.data.data.consultor.company.name
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_update_contractor({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.update_contractor(data).then(
                (response) => {
                    var result = response.data.data
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.contracter_id = result.contracter_id
                            if (result.contracter != null) {
                                if (result.contracter.company != null)
                                    v.contracter.company.name = result.contracter.company.name
                            }
                            v.status_id = result.status_id
                            v.status.status = result.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_change_consulting({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.change_consulting(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.contracter_id = response.data.data.contracter_id
                            v.consulting_id = response.data.data.consulting_id
                            v.garbage_id = response.data.data.garbage_id
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_change_contractor({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            return OrderService.change_contractor(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.contracter_id = response.data.data.contracter_id
                            v.consulting_id = response.data.data.consulting_id
                            v.garbage_id = response.data.data.garbage_id
                            v.status_id = response.data.data.status_id
                            v.status.status = response.data.data.status.status
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },

        action_approve_consultor({ rootState }, data) {
            return OrderService.approve_dis_consultor(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == data.order_id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },
        action_approve_constructor({ rootState, commit }, data) {
            return OrderService.approve_dis_contracter(data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    commit('form/SET_LOADER', false, { root: true })
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    commit('form/SET_LOADER', false, { root: true })
                    commit("form/SET_NOTIFY", {
                        msg: error.response.data.message,
                        type: "Danger"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },
        action_disapprove({ rootState }, data) {
            if (data.type == "design_office") {
                var url = 'general/orders/consulting';
            } else if (data.type == "contractor") {
                var url = 'general/orders/contracting';
            }
            // console.log('data', data)
            return axios.post(url, data.data).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == data.data.order_id) {
                            v.status.status = response.data.data.status.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },

        action_rejected_tsleem({ rootState, commit, state }, data) {
            console.log(...data)
            data.append('order_id', rootState.id)
            data.append('current_status', state.order.status_id)
            // return
            return OrderService.rejected_order(data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('SET_ORDER', response.data.data)
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },
        action_approve_tsleem({ rootState, commit, state }, data) {
            console.log(...data)
            data.append('order_id', rootState.id)
            data.append('current_status', state.order.status_id)
            return OrderService.approve_order(data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('SET_ORDER', response.data.data)
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },

        action_disapprove_spacilist_tsleem({ rootState, commit, getters }, data) {
            data.append('id', rootState.id);
            return OrderService.disapprove_spacilist_order(data).then(
                (response) => {
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('form/SET_NOTIFY', { msg: response.data.message, type: "Success" }, { root: true })
                    console.log("params", router);

                    return OrderService.detail_specialist_services_by_order(router.history.current.params.id).then(
                        (response) => {

                            commit('SET_ORDER_SPACILIST', response.data.order_specialists);
                            getters.get_order_specialists.hide_btn_spacilist = true
                            // commit('HIDE_REJECT_SPACILIST', true);
                        }, (error) => {
                            store.commit("form/SET_NOTIFY", {
                                msg: error.response.data.message,
                                type: "Danger",
                            }, { root: true });
                        })
                    // router.push({
                    //     name: "OrderPage",
                    //     params: { message: { msg: response.data.message, type: "Success" } },
                    // });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },

        action_approve_sharer({ rootState, commit, state }, data) {
            // console.log(...data)
            return OrderService.approve_sharer(rootState.id, data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('SET_ORDER', response.data.data)
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },

        action_disapprove_sharer({ rootState, commit }, data) {
            // console.log(...data)
            return OrderService.disapprove_sharer(rootState.id, data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('SET_ORDER', response.data.data)
                    router.push({
                        name: "OrderPage",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },

        action_upload_gis({ rootState, commit }, data) {
            return OrderService.uploadGis(rootState.id, data).then(
                (response) => {
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('form/SET_NOTIFY', { msg: response.data.message, type: "Success" }, { root: true })
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                    return Promise.reject(error);
                }
            );
        },




        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            // return OrderService.get_orders(rootState.table.paginate.page, formData).then(
            return axios.post(rootState.url + '?page=' + rootState.table.paginate.page, formData).then(
                (response) => {
                    // console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                        rootState.form.filter_year_id = null
                        rootState.form.filter_year = false
                    }
                    if (rootState.form.filter_year_id != null)
                        rootState.form.filter_year = true
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },


};