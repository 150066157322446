import store from "@/store"
export function set_obj(object, index, name, obj) {
    // console.log(object, " " + index + " " + name + " " + obj);
    if (index == 0) {
        if (object.status == "pending") {
            // this.show = true;
            obj = {
                isLoading: false,
                pledge_file: object.full_path,
                pledge_name: name,
                id: object.id,
                status_file: null,
                status: "pending",
                value: null,
                color: "#17c1e8",
                details: [],
                old: null,
            };
        } else if (object.status == "rejected") {
            // this.show = true;
            store.commit("order/SET_STATUS", true);
            var data = {
                pledge_name: name,
                pledge_file: object.full_path,
                more: false,
                color: "#f5365c",
                details: [],
                status: "rejected",
            };
            var old = object;
            obj = Object.assign(data, old);
        } else if (object.status == "replaced") {
            // this.show = true;
            obj = {
                isLoading: false,
                pledge_file: object.full_path,
                pledge_name: name,
                id: object.id,
                status_file: null,
                status: "replaced",
                color: "#fbcf33",
                value: null,
                old: null,
                more: false,
                details: [],
            };
        } else if (object.status == "approved") {
            store.commit("order/HIDE_REJECT_SPACILIST", true);
        }
    } else if (index == 1) {
        obj.old = object;
    } else {
        obj.more = true;
        obj.details.push(object);
    }
    return obj;
}