import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "PowerMetersOrder",
    children: [
        // {
        //     path: "/power-meters-order/create",
        //     name: "NewPowerMetersOrder",
        //     component: () => import("@/modules/orders/views/PowerMetersOrder/New.vue"),
        //     meta: {
        //         groupName: "PowerMetersOrder",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //             Middleware.signeture,
        //         ],
        //         permissions: "power-meter-create",
        //     },
        // },
        // {
        //     path: "/power-meters-order/details/:id",
        //     name: "EditPowerMetersOrder",
        //     component: () => import("@/modules/orders/views/PowerMetersOrder/Details.vue"),
        //     meta: {
        //         groupName: "PowerMetersOrder",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //             Middleware.signeture,
        //         ],
        //         permissions: "readiness-details",
        //     },
        // },
        {
            path: "/power-meters-order",
            name: "PowerMetersOrderPage",
            component: () => import("@/modules/orders/views/PowerMetersOrder/Show.vue"),
            meta: {
                groupName: "PowerMetersOrder",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-power-meter-index",
            },
        },

    ],

}