export const reAssignOrder = {
    action: "fa fa-box",
    title: "ReAssignOrders",
    permission: "re-assign",
    items: [
        {
            prefix: "O",
            title: "ReAssignOrders",
            link: "/re-assign-orders",
            permission: "re-assign-index",
        },
        {
            prefix: "O",
            title: "AboutSignature",
            link: "/about-signature",
            permission: "re-assign-signature",
        },

    ]
}